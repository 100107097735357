import io from 'socket.io-client';

let socket;
// let auth = {};

export const setupSocket = (url, opts = {}, cb) => {
  if (socket?.connected) return console.log('Socket already connected.');

  const defaultOpts = opts || { withCredentials: true };
  socket = io(url, defaultOpts);
  socket.on('connect', (s) => {
    console.log('Connected!', socket?.id);
    cb && cb();
  });

  socket.on('data', (data) => {
    console.log('Data', data);
  });

  socket.on('error', (error) => {
    console.warn(error);
    cb && cb({ error });
  });
  socket.on('connect_error', (error) => {
    console.warn(error);
    cb && cb({ error });
    // setTimeout(() => {
    //   socket = io(url, defaultOpts);
    // }, 1000);
  });
};

export const socketOn = (msg, cb) => {
  console.log('Registering Event (socketOn): ', msg);
  socket.off(msg);
  socket.on(msg, cb);
};

export const socketEmit = (msg, data, cb) => {
  socket.emit(msg, data, (res) => {
    cb && cb(res);
  });
};

export const enterGame = (data, cb) => {
  socket.emit('enter', data, (gameData) => {
    // auth = data;
    cb(gameData);
  });
};

export const enterBackend = (data, cb) => {
  socket.emit('enter-backend', data, (gameData) => {
    // auth = data;
    cb(gameData);
  });
};

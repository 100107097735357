import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
// import queryString from 'query-string';

import { authActions } from '../actions';

import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
import appLogo from '../assets/app_icon.svg';

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { dispatch, location } = this.props;
    if (location.search.length > 0)
      setTimeout(dispatch(authActions.authenticate(location.search)), 2000);
    else this.setState({ error: 'Missing code' });
  }

  render() {
    return (
      <div className='row bgcolors'>
        <div
          className='panel-sidebar col-3 text-center d-none d-md-block'
          style={{ height: '100vh' }}>
          <p className='v-center'>
            <img
              src={scLogoWide}
              className='mb-3'
              style={{ maxWidth: '50%' }}
              alt='SimCase'
            />
            <br />
            <small>
              <a href='http://simcase.io' target='_blank'>
                Learn More
              </a>
            </small>
          </p>
        </div>
        <div
          className='col-12 col-sm-10 offset-sm-1 col-md-5 offset-md-5 text-center'
          style={{ height: '100vh' }}>
          <div className='v-center'>
            <div className='card'>
              <div className='card-body pt-5 pb-5'>
                <h3 className='mt-0 mb-0 title-font font-weight-light'>
                  Authenticating...
                </h3>
                <img
                  src={appLogo}
                  style={{ maxHeight: '150px' }}
                  className='img-fluid my-5'
                  alt=''
                />
                <br />
                <p className='mt-5 mb-0 d-md-none text-center'>
                  <img src={scLogo} style={{ width: '25px' }} alt='' />
                  <br />
                  <small className='text-secondary'>Developed by Simcase</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedAuthPage = connect(mapStateToProps)(AuthPage);
export { connectedAuthPage as AuthPage };

import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';
import { CSVLink } from 'react-csv';
// import { Tooltip } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// Actions
import { gameActions } from '../../actions';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      tip: false,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
    this.toggleTip = this.toggleTip.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  toggleTip() {
    this.setState({ tip: !this.state.tip });
  }

  render() {
    const { game } = this.props;
    const players = game.data.players || [];

    const playersList = players.map((player) => {
      const hasPerformance = player?.performance?.find(
        (p) => typeof p.final !== 'undefined'
      );

      const theEntity = hasPerformance
        ? player
        : player?.snapshots[player?.snapshots.length - 1];

      const progress =
        (theEntity?.results.filter((r) => r.confirmed && r.accepted).length /
          3) *
        100;

      const thePlayerPerformance = theEntity?.performance?.reduce(
        (acc, performance) => {
          // Get the avg between initial and final performance
          const avg = (performance?.initial + performance?.final) / 2 || 0;
          // Add the avg to the performance
          acc.push(avg);
          return acc;
        },
        []
      );

      const playerAvgPerformance = thePlayerPerformance
        ? thePlayerPerformance?.reduce((a, b) => a + b, 0) /
          thePlayerPerformance?.length
        : 0;

      return {
        name: player.name,
        lastname: player.lastname,
        email: player.email,
        runs: hasPerformance
          ? player.snapshots.length + 1
          : player.snapshots.length,
        results: player.results || [],
        progress: {
          value: progress,
          label: numeral(progress).format('0') + '%',
        },
        performance: {
          value: playerAvgPerformance < 0 ? 0 : playerAvgPerformance,
          label:
            numeral(playerAvgPerformance < 0 ? 0 : playerAvgPerformance).format(
              '0'
            ) + '%',
        },
      };
    });

    // Has any participant completed an event
    const hasAnyCompleted = true;

    // Sorted playersList by performance
    const sortedPlayersList = playersList
      .sort((a, b) => {
        return b.performance.value - a.performance.value;
      })
      .map((player, index) => {
        // Add the rank property to the player
        player.rank = index + 1;
        return player;
      });

    const csv_data = sortedPlayersList.reduce((acc, player) => {
      const player_data = {
        name: player.name,
        lastname: player.lastname,
        email: player.email,
        runs: player.runs,
        progress: player.progress.label,
        performance: player.performance.label,
        rank: player.rank,
      };
      const player_results = [1, 2, 3].reduce((lacc, level) => {
        // Level default data
        const level_default = {
          level: { id: level },
          amount: null,
          accepted: 'N/A',
          confirmed: 'N/A',
        };

        // Get the result for the order type
        const result_order = player.results.find(
          (r) => r.type === 'order' && r.level?.id === level
        ) || {
          ...level_default,
          type: 'order',
        };
        // Get the result for the offer type
        const result_promo = player.results.find(
          (r) => r.type === 'promo' && r.level?.id === level
        ) || {
          ...level_default,
          type: 'promo',
        };
        // Get the result for the negotiation type
        const result_counteroffer = player.results.find(
          (r) => r.type === 'counteroffer' && r.level?.id === level
        ) || {
          ...level_default,
          type: 'promo',
        };

        const level_results = [
          {
            ...player_data,
            ...result_order,
            level: result_order.level.id,
            type: 'Initial',
            accepted: 'N/A',
          },
          {
            ...player_data,
            ...result_promo,
            level: result_order.level.id,
            type: 'ComputerDeal',
            accepted: 'N/A',
          },
          {
            ...player_data,
            ...result_counteroffer,
            level: result_order.level.id,
            type: 'Counter',
          },
        ];

        // Add the level results to the level accumulator
        lacc = [...lacc, ...level_results];
        return lacc;
      }, []);

      // Add the player results to the accumulator as a flat array
      acc = [...acc, ...player_results];
      return acc;
    }, []);

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
          <small>
            <CSVLink
              className={`btn btn-sm btn-link ml-3 ${
                hasAnyCompleted ? '' : 'disabled'
              }`}
              filename={`EOQ_Leaderboard_${game?.data?.name}_${game?.data?.created_at}.csv`}
              headers={[
                { label: 'First Name', key: 'name' },
                { label: 'Last Name', key: 'lastname' },
                { label: 'Email', key: 'email' },
                { label: 'Runs', key: 'runs' },
                { label: 'Progress', key: 'progress' },
                { label: 'Performance', key: 'performance' },
                { label: 'Rank', key: 'rank' },
                { label: 'Level', key: 'level' },
                { label: 'Order', key: 'type' },
                { label: 'Amount', key: 'amount' },
                { label: 'Accepted', key: 'accepted' },
                { label: 'Submitted', key: 'confirmed' },
              ]}
              data={csv_data}>
              Export data
            </CSVLink>
          </small>
        </h2>

        <div className='mt-3' style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Surname',
              'Email',
              'Runs',
              'Progress',
              'Precision',
              'Rank',
              // <span>
              //   Precision{' '}
              //   <FontAwesomeIcon
              //     id='tip'
              //     icon='question-circle'
              //     size='1x'
              //     className='icon text-dark'
              //   />
              // </span>,
            ]}
            fields={[
              'name',
              'lastname',
              'email',
              'runs',
              'progress',
              'performance',
              'rank',
            ]}
            sortables={[
              'name',
              'lastname',
              'email',
              'runs',
              'progress',
              'performance',
              'rank',
            ]}
            sort={1}
            sortedBy={4}
            data={sortedPlayersList}
            onRowClick={this.rowClick}
          />
          {/* <Tooltip isOpen={this.state.tip} target='tip' toggle={this.toggleTip}>
            Precision is defined as the number of correct responses divided by
            the total number of responses submitted.
          </Tooltip> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
